/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

// $base-fonts-path : '/wp-content/themes/carrie/assets/fonts';
@font-face {
    font-family: 'neuf';
    src: url(https://annesophiebarlet.com/assets/fonts/Neuf-TailleDouce.otf);
    font-weight: 300;
    font-style: normal;
}
