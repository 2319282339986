$bordure: 20px;

header {

    display: flex;
    justify-content: space-between;
    margin: 0px $bordure;
    color: white;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100000;
    width: calc(100vw - 40px);

    @include mobile {

        top: 3px;
        flex-direction: column;

    }
    
    a {

        color: white;

    }

    h1 {

        padding: 3.5px;
        height: 13px;
        z-index: 1000;

        transition: opacity 0.5s ease;

        #first {

            margin-right: 30px;

            .as {

                margin-right: 9.5px;

            }

        }


        &:hover {

            .second {

                opacity: 0;

                @include mobile {

                    opacity: 1;

                }


            }
            
            ~ .base {

                opacity: 1;

                @include mobile {

                    opacity: 0;

                }
    
            }

        }

    }

    .second {

        transition: opacity 0.5s ease;

    }

    .base {

        opacity: 0;
        position: absolute;
        top: 0px;
        padding: 3.5px;
        height: 13px;
        left: 68px;
        z-index: 100;
        color: white;

        transition: opacity 0.5s ease;

        @include mobile {

            left: 70px;
            top: 22px;

        }

    }

    .burger {

        position: fixed;
        top: 3px;
        right: 20px;
        width: 11px;
        height: 11px;
        padding: 3.5px 5px;
        display: none;
        z-index: 1000;
        transform-origin: center;
        cursor: pointer;

        transition: transform 0.5s ease;

        @include mobile {

            display: block;

        }

    }

    .back {

        width: 100vw;
        height: 20px;
        position: absolute;
        top: 0px;
        left: -20px;
        display: none;
        z-index: 100;
        background-color: $brown;

        transition: height 0.5s ease;
        will-change: height;

        @include mobile {

            display: block;

        }

    }

    .navigation {

        width: calc(50vw - 20px + 45px);
        display: flex;
        justify-content: space-between;
        z-index: 100000;
        
        a {

            color: white;
            text-decoration: none;

        }

        transition: opacity 0.4s ease;

        &_item {

            padding:3.5px;
            height: 13px;
            position: relative;

            @include mobile {
            
                
                width: calc(100vw - 40px);

            }

            &.item_mentions {

                position: absolute;
                width: 100vh;
                transform: rotate(-90deg);
                right: -10px;
                top: -40px;
                transform-origin: right center;
                left: unset;
                padding-left: 5px;

                transition: opacity 0.3s ease;

                &::before {

                    top: -0.5px;

                }

                @include mobile {

                    opacity: 0;
                    top: 0px;
                    width: calc((var(--vh)* 100) - 40px);
                    pointer-events: none;

                }

            }
    
            &::before  {
    
                content: '';
                height: calc(100% + 3px);
                width: calc(100% + 6px);
                position: absolute;
                top: -3.5px;
                left: -3px;
                background-color: black;
                z-index: -1;
                transform: scaleX(0);
                transform-origin: 100% 0;
    
                transition: transform 0.3s ease
        
            }
    
            &:hover {
            
                &::before  {
    
                    transform: scaleX(1);
                    transform-origin: 0% 0;
            
                }
    
            }

            &.selected {

                &::before  {
    
                    transform: scaleX(1);
                    transform-origin: 0% 0;
            
                }

            }

        }

        @include mobile {

            flex-direction: column;
            position: absolute;
            bottom: -123px;
            opacity: 0;
            pointer-events: none;

        }

    }

    .cube {

        width: 20px;
        height: 20px;
        position: fixed;
        background-color: black;
        z-index: 1000;

        transition: height 0.5s ease;
        will-change: height;

        @include mobile {

            height: 23px;

        }

    }

    .top { top: 0px }
    .bottom { bottom: 0px }
    .left { left: 0px }
    .right { right: 0px }


    &.open {

        .burger {

            transform: rotate(45deg);

        }

        .back {

            height: 144.1px;
    
        }

        // .navigation {

        //     opacity: 1;
        //     pointer-events: auto

        // }

        .item_mentions {

            opacity: 1;
            pointer-events: all;

        }

        .top.right, .top.left {

            height: 147.1px;

        }

        .base {

            opacity: 1

        }

    }

}