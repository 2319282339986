/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */


html, body {

    font-family: 'neuf', sans-serif;
    font-size: 0.86rem;
    text-transform: uppercase;
    line-height: 1.3;
    letter-spacing: 0.5px;
    color: $brown;

    @include mobile {

        height: 100%;
        overflow: hidden;

    }

}

body {

    border: 20px solid $brown;
    height: calc(100vh - 40px);
    color: $brown;
    overflow: hidden;

    @include mobile {

        height: calc(100% - 43px);
        border-bottom: 23px solid $brown;
        border-bottom: 23px solid $brown;

    }
}

a {

    cursor: pointer;
    color: $brown;
    text-decoration: none;

}

.mob {

    display: none;

    @include mobile {

        display: block;

    }

}

.footer {

    color: white;
    width: 100vw;

    .pagination {

        position: fixed;
        bottom: 1px;
        left: 50%;
        transform: translate(-50%, 0%);
        width: fit-content;

        @include mobile {

            bottom: 3px;

        }

    }

    .link_back {

        position: fixed;
        padding: 2px 5px;
        right: 20px;
        bottom: -1px;
        color: white;

        @include mobile {

            bottom: 1px;

        }

        span {

            @include mobile {

                display: none;

            }
            
        }

    }

    .button_sound {

        padding: 2px 5px;
        position: fixed;
        bottom: -1px;
        left: 23px;
        opacity: 0;
        pointer-events: none;

        transition: opacity 0.3s ease;

        &.active {

            opacity: 1;
            pointer-events: auto;
            cursor: pointer;
            bottom: 1px;

            @include mobile {

                margin: 0px;
                bottom: 3px;
                padding-left: 0px;
    
            }

        }

        @include mobile {

            margin: 0px;
            bottom: 3px;
            padding-left: 0px;

        }

    }


}
