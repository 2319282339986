.project {

    .carousel {

        width: $ecran_width;
        height: $ecran_height;

        &-cell {

            position: absolute;
            top: 50%;
            left: 50%;
            height: 70vh;
            width: 70vw;
            transform: translate(-50%, -50%);
            opacity: 0;
            z-index: 10000;

            transition: opacity 0.5s ease;

            @include mobile {

                max-height: calc((var(--vh) * 100) - 84px);
                height: unset;
                width: unset;
                overflow: hidden;

            }

            &.active {

                opacity: 1

            }

            img, video {

                height: 100%;
                width: 100%;
                object-fit: contain;
                z-index: 100;

                @include mobile {

                    width: 100vw;
                    height: auto;
                    object-fit: contain;
                    object-position: center;


                }
        
            }

        }

    }

    .marquee {

        margin: 0;
        display: flex;
        align-items: center;
        flex: 1;
        position: fixed;
        left: 0px; 
        bottom: calc(100vh / 3);
        mix-blend-mode: multiply;

        &::before  {

            display: block;
            white-space: pre;
            /* Abusing `content` property and `attr` function to duplicate content in CSS */
            content: attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text) "        " attr(data-text);
            width: fit-content;
            transform: translate3d(-2%, 0, 0);
            will-change: transform;
            animation: marquee var(--tw) linear infinite;

        }

        span {

            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;

        }

        @include mobile {

            bottom: 23px;

        }

    }


        .carousel_button {

            height: $ecran_height;
            width: calc((100vw - 40px) / 2);
            position: absolute;
            top: 20px;
            z-index: 10000;

            @include mobile {

                height: calc((var(--vh) * 100) - 70px);

            }

            &.button_next {

                right: 20px;
                cursor: e-resize;

            }

            &.button_prev {

                left: 20px;
                cursor: w-resize;

            }

        }

    }
    
    @keyframes marquee {
        0% { transform: translate3d(-2%, 0, 0);
        }
        100% { transform: translate3d(calc(-2% - 5% - 1px), 0, 0) }
    }

    .left_part_footer {

        position: fixed;
        bottom: -1px;
        left: 20px;
        width: fit-content;
        padding: 2px 5px;

        @include mobile {

            bottom: 1px;

        }

        .titre_projet {

            @include mobile {

                display: none;

            }

        }

        .button_sound {

            position: static;
            margin-left: 26px;

            @include mobile {

                margin-left: 0px;

            }

        }

    }
