.portfolio {

    width: 100%;
    height: calc(100vh - 34px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    @include mobile {

        height: calc((var(--vh) * 100) - 34px);

    }

    a {

        line-height: 1;
        padding: 3px 3px 0px 3px;
        margin-bottom: 2px;
        color: $brown;

        &:hover {

            background-color: $brown;
            color: white;
    
        }
    

    }
}