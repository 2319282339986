$ecran_width : calc(100vw - 40px);
$ecran_height: calc(100vh - 40px);
@include mobile {
    $ecran_height: calc((var(--vh) * 100) - 40px);
}

.home {

    width: $ecran_width;
    height: $ecran_height;
    overflow: hidden;

    img, video {

        width: $ecran_width;
        height: $ecran_height;
        object-fit: cover;
        
    }

    .carousel_wrapper {

        width: calc((100vw - 40px) / 2);
        overflow: hidden;
        height: $ecran_height;
        position: fixed;
        top: 20px;
        

        .carousel-cell {

            width: calc((100vw - 40px) / 2);
            height: calc(100vh - 40px);
            overflow: hidden;

            @include mobile {

                height: calc((var(--vh) * 100) - 40px);

            }

            .intro {

                position: absolute;
                bottom: calc(100vh / 3);
                color: $brown;
                left: 40px;
                font-size: 1.8rem;
    
            }

        }

        &.left_part {

            left: 20px;
            cursor: w-resize;

            .carousel-cell {

                position: absolute;
                left: calc(((100vw - 40px) / 2));
                top: 0px;

            }

        }

        &.right_part {

            right: 20px;
            cursor: e-resize;

            .carousel-cell {

                position: absolute;
                left: calc(((100vw - 40px) / 2));
                top: 0px;

                img, video {

                    position: absolute;
                    top: 0px;
                    right: 0px;
                    

                }

            }

        }

        @include mobile {

            display: none;

        }

    }

    .pagination {

        @include mobile {

            display: none;

        }

    }

    #carousel_mobile {

        .carousel-cell {

            height: $ecran_height;

        }

    }

    .intro_mobile {

        width: 100vw;
        position: fixed;
        bottom: 24px;
        left: 0px;
        font-size: 1.3rem;
        overflow: hidden;
        mix-blend-mode: multiply;

        &_wrapper {

            width: 3000px;
            padding-left: 100vw;

            .as {

                margin-right: 15px;

            }

            .b {


                margin-right: 70px;

            }
        
        }

    }

    .first .as {

        margin-right: 15px;

        @include mobile {

            margin-right: 10px;

        }

    }

}