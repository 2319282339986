.contact, .mention {

    width: 540px;
    height: calc(100vh - 34px);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include mobile {

        width: 100%;

    }

    a {

        position: relative;
        transition: color 0.3s ease;

        &::before  {

            content: '';
            height: 16px;
            width: calc(100% + 6px);
            position: absolute;
            top: -3.5px;
            left: -3px;
            background-color: $brown;
            z-index: -1;
            transform: scaleX(0);
            transform-origin: 100% 0;

            transition: transform 0.3s ease
    
        }

        &:hover {

            color: white;

            &::before  {

                transform: scaleX(1);
                transform-origin: 0% 0;
                
        
            }

        }

    }

}