.informations,
.mentions {

    width: 540px;
    margin: auto;
    padding: 20px;
    flex-direction: column;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    overflow: hidden;

    @include mobile {

        height: calc((var(--vh) * 100) - 82px);
        width: inherit;
        display: block;
        overflow: scroll;

    }

    #informations_content {

        p {

            margin-bottom: 20px;

        }

    }

    .client_part {

        text-align: center;
        margin-top: 60px;

        .title {

            margin-bottom: 20px;

        }

    }

    a {

        position: relative;
        transition: color 0.3s ease;

        &::after {

            content: '';
            height: 19px;
            width: calc(100% + 6px);
            position: absolute;
            top: -3.5px;
            left: -3px;
            background-color: $brown;
            z-index: -1;
            transform: scaleX(0);
            transform-origin: 100% 0;

            transition: transform 0.3s ease
        }

        &:hover {

            color: white;

            &::after {

                transform: scaleX(1);
                transform-origin: 0% 0;


            }

        }


    }

}

.mentions {

    justify-content: flex-start;

}